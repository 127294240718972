<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    :content-class="$vuetify.breakpoint.lgAndUp ? 'rounded-xl' : ''"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '800' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>
          Anmeldung hinzufügen
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center" class="mt-4">
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              :label="
                template.var.verband == 'DEU' ? 'ID (optional)' : 'Startnummer'
              "
              v-model="v.startnummer"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="Vorname"
              :rules="[rules.required, rules.name]"
              v-model="v.person.vorname"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="Nachname"
              :rules="[rules.required, rules.name]"
              v-model="v.person.name"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="Strasse + Nr."
              :rules="[rules.required, rules.street, rules.streetnr]"
              v-model="v.person.adresse"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="5" class="py-0">
            <v-text-field
              filled
              label="Postleitzahl"
              :rules="[rules.required, rules.plz]"
              v-model="v.person.plz"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              filled
              label="Ort"
              :rules="[rules.required, rules.name]"
              v-model="v.person.ort"
              rounded
            ></v-text-field>
          </v-col>
          <v-col
            cols="11"
            class="py-0"
            v-if="veranstaltung.data.nation_required"
          >
            <v-text-field
              filled
              label="Nation"
              :rules="[rules.required]"
              v-model="v.person.nation"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="Telefon"
              :rules="[rules.required, rules.telefon]"
              v-model="v.person.telefon"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-text-field
              filled
              label="E-Mail"
              :rules="[rules.email]"
              v-model="v.person.email"
              rounded
            ></v-text-field>
          </v-col>
          <v-col cols="11" class="py-0">
            <v-select
              filled
              label="Funktion"
              :items="aktivitaeten"
              :rules="[rules.required]"
              v-model="v.person.aktivitaet"
              rounded
            ></v-select>
          </v-col>
          <v-col cols="11" class="py-0" v-if="disziplinen.length > 0">
            <v-select
              filled
              multiple
              :label="template.var.verband == 'DEU' ? 'Gruppen' : 'Disziplinen'"
              :items="disziplinen"
              item-text="name"
              item-value="id"
              rounded
              v-model="v.disziplinen"
            ></v-select>
          </v-col>
          <v-col cols="11" class="py-0">
            <ggswitches
              :geimpft="false"
              :getestet="false"
              :genesen="false"
              :datum="false"
              @valid="validate_ggg($event)"
              @change="get_ggg_data($event)"
            ></ggswitches>
          </v-col>
          <v-col cols="11" class="pt-0 text-center">
            <v-btn
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              large
              rounded
              :disabled="!check_valid() || !ggg_valid"
              :loading="creating"
              @click="create()"
            >
              <v-icon class="mr-1">mdi-plus</v-icon>
              Anmeldung hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from 'firebase'
import { mapGetters } from 'vuex'
import ggswitches from '../../../2G-Regel/switches.vue'

export default {
  name: 'Disziplin-erstellen',
  components: {
    ggswitches,
  },
  data() {
    return {
      v: {
        person: {
          name: '',
          vorname: '',
          adresse: '',
          plz: '',
          ort: '',
          telefon: '',
          nation: '',
          email: '',
          aktivitaet: '',
        },
        geimpft: false,
        genesen: false,
        geburtsdatum: false,
        startnummer: '',
        disziplinen: [],
      },
      ggg_valid: false,
      aktivitaeten: [],
      disziplinen: [],
      sort: 'Startnummer',
      beginndatum: false,
      beginnuhrzeit: false,
      endedatum: false,
      creating: false,
      bisdatum: false,
      valid: false,
      vondatum: false,
      endeuhrzeit: false,
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
        email: (v) =>
          v.match(
            /^((?!\.)[\w-_.]*[^.])(@\w+)((?!\.)[\w-_.]*[^.])(\.\w+(\.\w+)?[^.\W])$/,
          )
            ? true
            : 'Bitte gebe eine gültige E-Mail Adresse ein',
      },
      counter: 0,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
    testpflicht_required() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.testpflicht) {
          if (!this.user.data.hygienekonzept.gg_regel) {
            return true
          }
        }
      }
      return false
    },
    gg_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.gg_regel) {
          return true
        }
      }
      return false
    },
    ggplus_regel() {
      if (this.user.data && this.user.data.hygienekonzept) {
        if (this.user.data.hygienekonzept.ggplus_regel) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    firebase
      .firestore()
      .collection('User')
      .doc(this.user.data.uid)
      .collection('Veranstaltung')
      .doc(this.veranstaltung.id)
      .collection('Disziplin')
      .onSnapshot((snap) => {
        this.disziplinen = []
        snap.forEach((doc) => {
          this.disziplinen.push({
            name:
              doc.data().name +
              (doc.data().altersklasse
                ? ' (' + doc.data().altersklasse + ')'
                : ''),
            daten: doc.data(),
            id: doc.id,
          })
        })
      })
    this.aktivitaeten = this.template.var.standard_aktivitaeten
    if (
      this.user.data.aktivitaeten.length >
      this.template.var.standard_aktivitaeten.length
    ) {
      this.user.data.aktivitaeten.forEach((aktivitaet) => {
        if (
          !this.template.var.standard_aktivitaeten.includes(aktivitaet) ||
          this.template.var.public_aktivitaeten.includes(aktivitaet)
        ) {
          this.aktivitaeten.push(aktivitaet)
        }
      })
    }
  },
  methods: {
    validate_ggg(daten) {
      this.ggg_valid = daten
    },
    get_ggg_data(daten) {
      if (daten) {
        this.v.geimpft = daten.ggg.geimpft || false
        this.v.genesen = daten.ggg.genesen || false
        this.v.geburtsdatum = daten.geburtsdatum || false
      }
    },
    closing() {
      this.$emit('closing', true)
    },
    parse_datum(datum) {
      if (datum) {
        var d = datum.split('-')
        return d[2] + '.' + d[1] + '.' + d[0]
      } else {
        return datum
      }
    },
    addNull(val) {
      if (val >= 10) {
        return val
      } else {
        return '0' + val
      }
    },
    check_valid() {
      if (
        this.v.person.vorname &&
        this.v.person.name &&
        this.v.person.adresse &&
        this.v.person.plz &&
        this.v.person.ort &&
        (this.veranstaltung.data.nation_required
          ? this.v.person.nation
          : true) &&
        this.v.person.telefon &&
        this.v.person.aktivitaet
      ) {
        return true
      } else {
        return false
      }
    },
    get_selected_disziplinen() {
      var selection = []
      for (let i = 0; i < this.disziplinen.length; i++) {
        for (let j = 0; j < this.v.disziplinen.length; j++) {
          if (this.v.disziplinen[j] == this.disziplinen[i].id) {
            selection.push(this.disziplinen[i])
          }
        }
      }
      return selection
    },
    async create() {
      if (this.check_valid()) {
        this.creating = true
        var selected_disziplinen = this.get_selected_disziplinen()
        var daten = {
          person: this.v.person,
          geimpft: this.v.geimpft || false,
          genesen: this.v.genesen || false,
          disziplinen: selected_disziplinen,
          disziplinen_ids: this.v.disziplinen,
          startnummer: this.v.startnummer,
        }
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Anmeldung')
          .add(daten)
          .then((docRef) => {
            this.creating = false
            this.closing()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
