<template>
  <div style="position: absolute; left: 0; width: 100vw;" class="pb-5">
    <v-row
      v-if="!veranstaltung.loader && veranstaltung.data"
      justify="center"
      class="pt-5"
    >
      <v-col
        cols="12"
        md="10"
        lg="8"
        :style="`color: ${template.colors.inline_primary_text};`"
      >
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn icon :to="`/wettkampf/${veranstaltung.id}`" exact>
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="text-center" align-self="center">
            <v-icon x-large>
              mdi-podium
            </v-icon>
          </v-col>
          <v-col>
            <h3 class="white--text font-weight-light">
              {{ veranstaltung.data.art }}
            </h3>
            <h2
              class="font-weight-bold"
              :style="`template: ${template.colors.inline_primary_text};`"
            >
              {{ veranstaltung.data.name }}
            </h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" class="px-5">
        <v-card class="rounded-xl">
          <v-toolbar
            :elevation="0"
            :color="template.colors.primary"
            :style="`color: ${template.colors.inline_primary_text};`"
          >
            <v-toolbar-title
              :style="`color: ${template.colors.inline_primary_text};`"
              class="font-weight-bold"
            >
              Anwesenheit verwalten
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              :color="template.colors.inline_primary_text"
              @click="search ? (search = false) : (search = true)"
            >
              <v-icon>
                {{ search ? 'mdi-magnify-close' : 'mdi-magnify' }}
              </v-icon>
            </v-btn>
            <v-btn
              icon
              :color="template.colors.inline_primary_text"
              @click="
                select_sort ? (select_sort = false) : (select_sort = true)
              "
            >
              <v-icon>
                {{ select_sort ? 'mdi-close' : 'mdi-sort' }}
              </v-icon>
            </v-btn>
            <v-menu :color="template.colors.primary" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="template.colors.inline_primary_text"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list rounded>
                <v-list-item @click="newanmeldung = true">
                  <v-list-item-avatar>
                    <v-icon>mdi-plus</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>Anmeldung hinzufügen</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="`/wettkampf/${veranstaltung.id}/anmeldungen-csvimport`"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-import</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title>
                    CSV-Import
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-expand-transition>
            <v-row justify="center" v-if="search">
              <v-col cols="11">
                <v-text-field
                  solo
                  light
                  prepend-inner-icon="mdi-magnify"
                  label="Suche"
                  class="rounded-xl mt-2"
                  @input="suchen()"
                  v-model="search_text"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row justify="center" v-if="select_sort">
              <v-col cols="11">
                <v-select
                  label="Sortierung"
                  solo
                  light
                  rounded
                  prepend-inner-icon="mdi-sort"
                  :items="sort_auswahl"
                  v-model="sortierung"
                  @change="sortieren()"
                  class="rounded-xl mt-2"
                  :hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-row
            justify="center"
            class="text-center"
            v-if="anmeldungen_gefiltert.length == 0"
          >
            <v-col cols="10">
              <h3 v-if="!search_text">
                Für diesen Wettkampf haben sich noch keine Personen angemeldet.
              </h3>
              <h3 v-if="search_text" class="mb-5">
                Deine Suche ergab keine Treffer.
              </h3>
            </v-col>
            <v-col cols="10" v-if="!search_text">
              <v-btn
                class="rounded-xl"
                large
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                @click="newanmeldung = true"
              >
                Anmeldung hinzufügen
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="
              anmeldungen_gefiltert.length > 0 && $vuetify.breakpoint.mdAndUp
            "
            class="px-3"
          >
            <v-col cols="12">
              <v-row v-for="(anm, i) in anmeldungen_gefiltert" :key="i">
                <v-col cols="11">
                  <v-card
                    class="rounded-xl pa-4"
                    :color="template.colors.blocks"
                    :dark="template.isDark(template.colors.blocks)"
                    :light="!template.isDark(template.colors.blocks)"
                  >
                    <v-row>
                      <v-col cols="auto" align-self="center">
                        <v-icon x-large>mdi-check-outline</v-icon>
                      </v-col>
                      <v-col cols="auto" align-self="center">
                        <h3 class="font-weight-light pr-2">
                          {{ anm.startnummer }}
                        </h3>
                      </v-col>
                      <v-col align-self="center">
                        <h3>{{ anm.person.vorname }} {{ anm.person.name }}</h3>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="1" align-self="center">
                  <v-btn
                    icon
                    :to="`/wettkampf/${veranstaltung.id}/anwesenheit/${anm.id}`"
                  >
                    <v-icon>mdi-clock-check-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row
            v-if="
              anmeldungen_gefiltert.length > 0 && $vuetify.breakpoint.smAndDown
            "
            class="px-3"
          >
            <v-col cols="12">
              <v-row v-for="(anm, i) in anmeldungen_gefiltert" :key="i">
                <v-col cols="12">
                  <card-button
                    :bgcolor="template.colors.primary"
                    :textcolor="template.colors.inline_primary_text"
                    chev
                    :icon="'mdi-account'"
                    :title="
                      anm.startnummer +
                      ' - ' +
                      anm.person.vorname +
                      ' ' +
                      anm.person.name
                    "
                    :to="`/wettkampf/${veranstaltung.id}/anmeldungen/${anm.id}`"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <AddAnmeldung v-if="newanmeldung" @closing="newanmeldung = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from 'firebase'
import store from '../../../../../store'
import router from '../../../../../routes/index'
import AddAnmeldung from './anmeldung_hinzufuegen'
import CardButton from '../../../../../elements/cards/card-button.vue'

export default {
  name: 'LA_Disziplinen',
  data() {
    return {
      anz_teilnehmer: 0,
      zuschauerinfo: false,
      veranstaltungedit: false,
      editable: true,
      sportstaette: '',
      anmeldungen: [],
      anmeldungen_gefiltert: [],
      newanmeldung: false,
      csvimport: false,
      search: false,
      search_text: '',
      select_sort: false,
      disziplinen: [],
      sortierung: 'Startnummer (aufsteigend)',
      sort_auswahl: [
        'Startnummer (aufsteigend)',
        'Startnummer (absteigend)',
        'Name (aufsteigend)',
        'Name (absteigend)',
        'Vorname (aufsteigend)',
        'Vorname (absteigend)',
      ],
    }
  },
  components: {
    AddAnmeldung,
    CardButton,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      veranstaltung: 'veranstaltung',
      template: 'template',
    }),
  },
  mounted() {
    if (this.template.var.verband == 'DEU') {
      this.sort_auswahl = [
        'ID (aufsteigend)',
        'ID (absteigend)',
        'Name (aufsteigend)',
        'Name (absteigend)',
        'Vorname (aufsteigend)',
        'Vorname (absteigend)',
      ]
      this.sortierung = 'ID (aufsteigend)'
    }
    if (this.$route.params.veranstaltung) {
      store.dispatch('setVeranstaltung', this.$route.params.veranstaltung)
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .orderBy('person.name')
        .onSnapshot((snap) => {
          this.anmeldungen = []
          var i = 0
          snap.forEach((doc) => {
            this.anmeldungen.push(doc.data())
            this.anmeldungen[i].id = doc.id
            i++
          })
          this.sortieren()
          this.suchen()
        })
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Disziplin')
        .orderBy('beginn.datum', 'asc')
        .orderBy('beginn.uhrzeit', 'asc')
        .onSnapshot((snap) => {
          this.disziplinen = []
          snap.forEach((doc) => {
            this.disziplinen.push({
              name:
                doc.data().name +
                (doc.data().altersklasse
                  ? ' (' + doc.data().altersklasse + ')'
                  : ''),
              daten: doc.data(),
              id: doc.id,
            })
          })
        })
    } else {
      store.dispatch('closeVeranstaltung')
    }
  },
  methods: {
    sortieren() {
      function compareStartnrASC(a, b) {
        if (a.startnummer > b.startnummer) {
          return 1
        } else if (a.startnummer == b.startnummer) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return -1
          }
        } else {
          return -1
        }
      }
      function compareStartnrDESC(a, b) {
        if (a.startnummer > b.startnummer) {
          return -1
        } else if (a.startnummer == b.startnummer) {
          if (a.person.name > b.person.name) {
            return 1
          } else {
            return -1
          }
        } else {
          return 1
        }
      }
      function compareNameASC(a, b) {
        if (a.person.name > b.person.name) {
          return 1
        } else {
          return -1
        }
      }
      function compareNameDESC(a, b) {
        if (a.person.name > b.person.name) {
          return -1
        } else {
          return 1
        }
      }
      function compareVornameASC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return 1
        } else {
          return -1
        }
      }
      function compareVornameDESC(a, b) {
        if (a.person.vorname > b.person.vorname) {
          return -1
        } else {
          return 1
        }
      }

      if (this.sortierung == 'ID (aufsteigend)') {
        this.anmeldungen.sort(compareStartnrASC)
      }
      if (this.sortierung == 'ID (absteigend)') {
        this.anmeldungen.sort(compareStartnrDESC)
      }
      if (this.sortierung == 'Startnummer (aufsteigend)') {
        this.anmeldungen.sort(compareStartnrASC)
      }
      if (this.sortierung == 'Startnummer (absteigend)') {
        this.anmeldungen.sort(compareStartnrDESC)
      }
      if (this.sortierung == 'Name (aufsteigend)') {
        this.anmeldungen.sort(compareNameASC)
      }
      if (this.sortierung == 'Name (absteigend)') {
        this.anmeldungen.sort(compareNameDESC)
      }
      if (this.sortierung == 'Vorname (aufsteigend)') {
        this.anmeldungen.sort(compareVornameASC)
      }
      if (this.sortierung == 'Vorname (absteigend)') {
        this.anmeldungen.sort(compareVornameDESC)
      }
      this.select_sort = false
    },
    suchen() {
      this.anmeldungen_gefiltert = []
      if (this.search_text) {
        var suche = this.search_text.split(',')
        for (let s = 0; s < suche.length; s++) {
          for (let i = 0; i < this.anmeldungen.length; i++) {
            var add = false
            if (this.anmeldungen[i].person.vorname.startsWith(suche[s])) {
              add = true
            } else if (this.anmeldungen[i].person.name.startsWith(suche[s])) {
              add = true
            } else if (this.anmeldungen[i].person.name.startsWith(suche[s])) {
              add = true
            } else if (this.anmeldungen[i].startnummer.startsWith(suche[s])) {
              add = true
            } else if (
              this.disziplinen_to_array(
                this.anmeldungen[i].disziplinen,
              ).includes(suche[s])
            ) {
              add = true
            } else if (
              this.disziplinen_to_altersklasse(
                this.anmeldungen[i].disziplinen,
              ).includes(suche[s])
            ) {
              add = true
            }

            if (add) {
              if (!this.anmeldungen_gefiltert.includes(this.anmeldungen[i])) {
                this.anmeldungen_gefiltert.push(this.anmeldungen[i])
              }
            }
          }
        }
      } else {
        this.anmeldungen_gefiltert = this.anmeldungen
      }
    },
    print_disziplinen(daten) {
      var output = ''
      for (let i = 0; i < daten.length; i++) {
        if (i > 0) {
          output += ', '
        }
        output +=
          daten[i].name +
          (daten[i].altersklasse ? ' (' + daten[i].altersklasse + ')' : '')
      }
      return output
    },
    disziplinen_to_array(daten) {
      var output = []
      for (let i = 0; i < daten.length; i++) {
        output.push(daten[i].name)
      }
      return output
    },
    disziplinen_to_altersklasse(daten) {
      var output = []
      for (let i = 0; i < daten.length; i++) {
        if (daten[i].altersklasse) {
          if (!output.includes(daten[i].altersklasse)) {
            output.push(daten[i].altersklasse)
          }
        }
      }
      return output
    },
    setStartnummer(anmeldung) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .doc(anmeldung.id)
        .update({
          startnummer: anmeldung.startnummer,
        })
    },
    get_selected_disziplinen(anmeldung) {
      var selection = []
      for (let i = 0; i < this.disziplinen.length; i++) {
        for (let j = 0; j < anmeldung.disziplinen_ids.length; j++) {
          if (anmeldung.disziplinen_ids[j] == this.disziplinen[i].id) {
            selection.push(this.disziplinen[i])
          }
        }
      }
      return selection
    },
    setDisziplinen(anmeldung) {
      var selected_disziplinen = this.get_selected_disziplinen(anmeldung)
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .collection('Anmeldung')
        .doc(anmeldung.id)
        .update({
          disziplinen: selected_disziplinen,
          disziplinen_ids: anmeldung.disziplinen_ids,
        })
    },
  },
}
</script>
